.app-container {
  display: flex;
  height: 100vh;
}

.sidebar-container {
  width: 250px;
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  transition: width 0.3s;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.header-container {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  margin: 0;
  font-size: 24px;
}

.sidebar-item {
  padding: 15px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.sidebar-item:hover {
  background-color: #444;
}

.sidebar-item svg {
  margin-right: 10px;
}
