.parametrized_table {
  width: 100%;
  border-collapse: collapse;
}

.parametrized_table th,
.parametrized_table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.parametrized_table th {
  cursor: pointer;
  background-color: #f2f2f2;
}

.parametrized_table .undefined:after {
  content: ' \2191';
  visibility: hidden;
}

.parametrized_table .ascending:after {
  content: ' \2191';
}

.parametrized_table .descending:after {
  content: ' \2193';
}

th.actions.undefined:after {
  display: none;
}

.details-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-content {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  max-width: 500px;
  width: 100%;
}

.details-content h2 {
  margin-top: 0;
}

.parametrized_table td.actions {
  display: flex;
  justify-content: space-around;
}