.sidebar {
  width: 250px;
  transition: width 0.3s;
  overflow: hidden;
  background-color: #333333;
}

.sidebar.close {
  width: 60px;
}

.sidebar .sidebar-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  color: white;
  width: 20px;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 10px;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;
  line-height: 20px;
  height: 20px;
}

.sidebar-item svg {
  margin-right: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.sidebar-item:hover {
  background-color: #444;
}

.sidebar-item span {
  margin-left: 10px;
}

.sidebar.open .sidebar-item span {
  display: inline;
}

.sidebar.close .sidebar-item span {
  display: none;
}
