
.panel-container {
  position: absolute;
  top: 0;
  right: -50%;
  width: 50%;
  bottom: 0;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 1s ease;
}

.panel-container.open {
  right: 0;
}

.panel-content {
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.close-panel, .panel-actions button {
  background-color: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.close-panel:hover, .panel-actions button:hover{
  background-color: darkred;
  
}

.panel-content h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid black;
  flex-grow: 0;
  flex-shrink: 0;
}

.panel-formdata {
  position: relative;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
}

.panel-info {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
}

.panel-field {
  position: relative; 
  line-height: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-grow: 0;
  flex-shrink: 0;
}

.panel-field :not(strong) {
  width: 50%;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
  line-height: 20px;
}

.panel-field input, .panel-field select {
  border-top: unset;
  border-left: unset;
  border-right: unset;
  border-bottom: 2px solid #333;
  background-color: rgba(0, 0, 0, 0.1);
}

.panel_infodata {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.panel-actions {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  align-items: end;
  display: flex;
  justify-content: space-around;
}
